import { jewlrTheme as JewlrTheme } from "@jewlr/storybook/themes"

import jewlrTheme from "./theme/jewlr"
import portalTheme from "./theme/portal"

const selectedTheme = () => {
  if (sl.config.organization === "jewlr4") {
    return { ...JewlrTheme, ...jewlrTheme }
  }
  if (sl.config.organization === "portal") {
    return { ...JewlrTheme, ...portalTheme }
  }
}

const orgTheme = () => Object.assign({}, selectedTheme(), {})

export default orgTheme
