import baseComponents from "styles/theme/base-components"

const components = {
  ...baseComponents,
}

components.checkout = {
  ...components.checkout,
  shipMethod: {
    ...components.checkout.shipMethod,
    checkboxWrapper: { pl: "12px" },
  },
}
components.miniview = {
  ...components.miniview,
  wrapper: {
    top: "58px",
  },
}
components.mobileMenu = {
  ...components.mobileMenu,
  container: {
    paddingTop: "24px",
  },
  navButton: { padding: "16px 8px" },
  navItem: {
    borderBottom: "1px solid #E8E8E8",
  },
}

export default components
